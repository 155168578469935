<template>
  <div>
    <div v-if="!isAdd && !isDetail">
      <div class="card" style="display: flex">
        <div class="left">
          <el-button
            type="primary"
            icon="el-icon-plus"
            slot="reference"
            @click="addEnterpriseUrl"
            >添加企业</el-button
          >
        </div>
        <div class="line"></div>
      </div>
      <div class="table">
        <el-table :data="list" stripe>
          <el-table-column
            type="index"
            label="序号"
            width="80"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="app_name"
            label="应用名称"
            align="center"

          ></el-table-column>
          <el-table-column
            prop="corp_name"
            label="授权企业名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="client_system_str"
            label="会员体系"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="corpid"
            label="企业ID"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="create_time"
            label="状态更新时间"
            align="center"
          ></el-table-column>
          <el-table-column prop="action" align="center">
            <template slot="header">
              <el-tooltip
                class="item"
                effect="dark"
                content="编辑规则：编辑后，停用旧规则，用户将重新匹配签到规则"
                placement="top"
              >
                <div>
                  操作<i
                    class="el-icon-info"
                    style="margin-left: 5px; color: #409eff"
                  ></i>
                </div>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
               <el-dropdown trigger="click" @command="handleCommand">
                  <span class="el-dropdown-link">
                    请选择<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item  :command="beforeHandleCommand(scope.row,'edit')"   >编辑</el-dropdown-item>
                    <el-dropdown-item  :command="beforeHandleCommand(scope.row,'synchronous')" >同步客户</el-dropdown-item>
                      <el-dropdown-item  :command="beforeHandleCommand(scope.row,'unbundling')" >解绑企微</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
     <el-dialog
      :visible.sync="visiable"
      title="编辑企业微信号"
      :destroy-on-close="true"
      :before-close="cancel"
      :close-on-click-modal="false"
      :close-on-press-escape="false"

    >
      <el-form :model="enterpriseWeChatForm" :rules="rules" ref="enterpriseWeChatForm">
        <div class="form">
          <el-form-item
            label="企业名称"
            :label-width="formLabelWidth"
            prop="name"
          >
            <el-input
              v-model="enterpriseWeChatForm.corp_name"
              autocomplete="off"
              :disabled="true"
              style="width: 375px"
              placeholder="请输入企业名称"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="企业ID(CorpID)"
            :label-width="formLabelWidth"
            prop="AppID"
          >
            <el-input
              v-model="enterpriseWeChatForm.corpid"
              autocomplete="off"
              :disabled="true"
              style="width: 375px"
              placeholder="请输入企业ID(CorpID)"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="通讯录管理secret"
            :label-width="formLabelWidth"
            prop="corpid"
          >
            <el-input
              v-model="enterpriseWeChatForm.mail_list_secret"
              autocomplete="off"
              style="width: 375px"
              placeholder="请输入App Secret"
            ></el-input>
          </el-form-item>
          <el-form-item label="通讯录事件地址" :label-width="formLabelWidth">
            <el-input
              v-model="enterpriseWeChatForm.mail_event_url"
              autocomplete="off"
              style="width: 375px"
              placeholder="请输入通讯录事件地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="外部联系人管理secret" :label-width="formLabelWidth">
            <el-input
              v-model="enterpriseWeChatForm.client_secret"
              autocomplete="off"
              style="width: 375px"
              placeholder="请输入外部联系人管理secret"
            ></el-input>
          </el-form-item>
          <el-form-item label="外部联系人事件地址" :label-width="formLabelWidth">
            <el-input
              v-model="enterpriseWeChatForm.client_event_url"
              autocomplete="off"
              style="width: 375px"
              placeholder="请输入外部联系人事件地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="客服管理secret" :label-width="formLabelWidth">
            <el-input
              v-model="enterpriseWeChatForm.customer_service_secret"
              autocomplete="off"
              style="width: 375px"
              placeholder="请输入客服管理secret"
            ></el-input>
          </el-form-item>
        </div>
        <div style="text-align: right">
          <el-button
            type="primary"
            @click="submit()"
            :loading="loading"
            >保存</el-button
          >
          <el-button @click="cancel">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {getListQwsq,getRedirectAuthURL,getEdit,synchronCustomer,getUnlock} from "@/api/system/authorization/enterprise.js";
import { Loading } from 'element-ui';
export default {
  data() {
    return {
      enterprise8Url:"",//企业微信url
      isDetail: true,
      isAdd: false,
      list: [],
      visiable:false,//弹窗显示控制
      loading: false,
      formLabelWidth: "300px",
      enterpriseWeChatForm: {//企业微信提交数据
        ewechat_authinfo_id:"",//授权企微ID
        corpid:"",//企业ID
        corp_name:"",//企业名称
        corp_full_name:"",//企业全名
        mail_list_secret:"",//通讯录secret
        client_secret:"",//客户secret
        customer_service_secret:"",//客服secret
        mail_event_url:"",//通讯录事件地址
        client_event_url:""//外部联系人地址
      },
      rules: {

      },
    };
  },
  created() {

    this.getRedirectAuthURL();
  },
  components: {
  },
  methods: {//操作下拉菜单
    handleCommand(command) {
      console.log(command)
       switch (command.command) { //角标index获取为command.index
          case "edit":
              this.handleEdit(command.row)
              break;
          case "synchronous":
              this.synchronCustomer(command.row)
              break;
          case "unbundling":
              this.unbundling(command.row)
              break;

      }
    },
    beforeHandleCommand(row,command){
      return {
            'row': row,
            'command':command
        }
    },
    getList() {
      this.isDetail = false;
      this.isAdd = false;
      this.market_id = 0;
      getListQwsq().then((res) => {
        console.log(res);
        if (res.code === 1) {
          this.list = res.data;
        }
      });
    },//获取企业微信授权
    getRedirectAuthURL(){
      getRedirectAuthURL().then((res) => {
        if (res.code === 1) {
          this.enterprise8Url=res.data;
           this.getList();
          console.log(res)
        }
      })

    },//添加企业
    addEnterpriseUrl(){
      window.location.href = this.enterprise8Url;
    },//编辑
    handleEdit(row) {
      console.log(row);
      this.enterpriseWeChatForm=row;
      this.enterpriseWeChatForm={//企业微信提交数据
        ewechat_authinfo_id:row.ewechat_authinfo_id,//授权企微ID
        corpid:row.corpid,//企业ID
        corp_name:row.corp_name,//企业名称
        corp_full_name:'',//企业全名
        mail_list_secret:row.mail_list_secret,//通讯录secret
        client_secret:row.client_secret,//客户secret
        customer_service_secret:row.customer_service_secret,//客服secret
        mail_event_url:row.mail_event_url,//通讯录事件地址
        client_event_url:row.client_event_url//外部联系人地址
      }
      this.visiable=true;
      // this.isAdd = true;
      // this.market_id = row.market_id;
    },//同步
    synchronCustomer(row){
      console.log(row)
      if(row.client_system_str==""){
        this.$confirm('同步需要至少绑定一个会员体系!', '提示', {
          confirmButtonText: '确定',
          showCancelButton:false,
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.push({
            path: '/member/system/memberSystemBindList/index',
          })

        })

        return;
      }else{
        let loading = Loading.service({
          lock: true,
          text: '正在同步',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        synchronCustomer({ewechat_authinfo_id:row.ewechat_authinfo_id}).then((res) => {

          if (res.code === 1) {
            this.$message({
              type: "success",
              message: "同步成功!",
            });
            console.log(res)
          }else{
            this.$message({
              type: "error",
              message: "同步失败!",
            });
          }
          loading.close();
        }).catch(error=>{
          loading.close();
        })
        }

    },//解绑企业微信
    unbundling(row){
       this.$confirm('确定解绑该应用授权?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {

          getUnlock().then((res) => {
            console.log(res);
            if (res.code === 1) {
              this.$message({
                type: 'success',
                message: res.msg
              });
              this.getList();
            }
          })

        })
    },
    submit(){
      console.log(this.enterpriseWeChatForm)
      getEdit(this.enterpriseWeChatForm).then((res) => {
        if (res.code === 1) {
          console.log(res)
          this.$message({
            type: "success",
            message: "编辑成功!",
          });
          this.cancel();
        }
      })
    },
    cancel() {
      this.visiable = false;
      this.enterpriseWeChatForm = {};
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.middle {
  display: flex;
  height: 40px;
  overflow: hidden;
}
 .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
</style>
